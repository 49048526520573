/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
function Counters() {
  const [hoursPlayed, sethoursPlayed] = useState(0);
  const [whitelisted, setWhitelisted] = useState(0);

  useEffect(() => {
    // Send a GET request to the Node.js server
    console.log(API_URL+'/data/hoursPlayed');
    fetch(API_URL+'/data/hoursPlayed', {
      headers: {
        'x-api-key': API_KEY 
      }
    })
      .then(response => response.json())
      .then(data => sethoursPlayed(data.hours))
      .catch((error) => {
        console.error('Error:', error);
        sethoursPlayed(1500000)
      });
    
  }, []);
  useEffect(() => {
    // Send a GET request to the Node.js server
    fetch(API_URL+'/data/whitelisted', {
      headers: {
        'x-api-key': API_KEY 
      }
    })
      .then(response => response.json())
      .then(data => setWhitelisted(data.whitelistedUsers))
      .catch((error) => {
        console.error('Error:', error);
        setWhitelisted(1500)
      });
    
  }, []);
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={whitelisted}
              suffix="+"
              title="Whitelisted"
              description="Spieler, welche sich bereits für The Western Hills entschieden haben"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={hoursPlayed}
              suffix="+"
              title="Stunden"
              description="Spielzeit, welche alle Spieler zusammen bereits auf The Western Hills verbracht haben"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={50}
              suffix="+"
              title="Server"
              description="weltweit, arbeiten bereits mit unseren Systemen"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
